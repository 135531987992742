export default [{
    header: 'Postores',
    icon: 'BriefcaseIcon',
    children: [
        {
            title: 'Lista',
            route: 'bidders-list',
            icon: 'ListIcon',
            action: 'read',
		    resource: 'bidders'
        },
        {
            title: 'Nuevo',
            route: 'bidders-create',
            icon: 'PlusSquareIcon',
            action: 'create',
		    resource: 'bidders'
        }
    ]
}];