export default [{
	header: 'Licitaciones',
	icon: 'BookmarkIcon',
	children: [		
		{
			title: 'Lista',
			route: 'tenders-list',
			icon: 'ListIcon',
			action: 'read',
			resource: 'tenders'
		},
		{
			title: 'Invitaciones',
			route: 'tenders-invitations',
			icon: 'StarIcon',
			action: 'read',
			resource: 'invitations'
		},
	]
}];